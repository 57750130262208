// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tag-tag-js": () => import("./../src/templates/tag/tag.js" /* webpackChunkName: "component---src-templates-tag-tag-js" */),
  "component---src-templates-course-course-template-js": () => import("./../src/templates/course/course-template.js" /* webpackChunkName: "component---src-templates-course-course-template-js" */),
  "component---src-templates-post-post-template-js": () => import("./../src/templates/post/post-template.js" /* webpackChunkName: "component---src-templates-post-post-template-js" */),
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-courses-index-js": () => import("./../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

